// Libraries

import React from "react"
import styled from "styled-components"
// import { get, findIndex, includes } from "lodash"
import { graphql } from "gatsby"

// Components

import SEO from "../components/global/SEO"
import PageLayout from "../components/sharedPageLayout"
import InvestmentContent from "../components/investment"

import InvestmentsPage from "../components/investment/shared"

// Styles
const Page = styled.section`
  min-height: calc(100vh - 30px);
  text-align: center;
`
const InvestmentContainer = styled.div``

/*-----------------------------
Styles
-----------------------------*/

/*-----------------------------
Component
-----------------------------*/

const NoPost = styled.h3`
  width: calc(100% - 60px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media all and (max-width: 768px) {
    width: 100%;
  }
`

class PreviousInvestmentsPage extends InvestmentsPage {
  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    const $this = this
    const previousActiveInvestment = this.setCurrentActiveInvestment()
    const pageLayoutProps = {
      ...$this.props,
      slideChange: this.state.currentActive,
      title: "Previous Investments",
      image: previousActiveInvestment.investmentImages,
      page: "investment",
      investmentHeading: this.state.subTitle,
      responsiveDividerYOffset: 230,
      showDividerInResponsive: this.state.readMoreFlagStatus,
      readMoreFlagStatus: this.state.readMoreFlagStatus,
      showBackButton: true,
      setInvestmentBySlug: this.setInvestmentBySlug,
    }

    const INVESTMENT_DETAIL = this.state.noPost ? (
      <NoPost>Sorry there are no post available.</NoPost>
    ) : (
      <InvestmentContainer>
        <InvestmentContent
          {...$this.state}
          {...$this}
          {...previousActiveInvestment}
          location={this.props.location}
          investmentHeading={pageLayoutProps.investmentHeading}
        />
      </InvestmentContainer>
    )

    return (
      <>
        <SEO title={pageLayoutProps.title} />
        <PageLayout {...pageLayoutProps}>
          <Page>{INVESTMENT_DETAIL}</Page>
        </PageLayout>
      </>
    )
  }
}

export default PreviousInvestmentsPage

export const PreviousInvestmentQuery = graphql`
  query PreviousInvestmentQuery {
    allWordpressPage(filter: { slug: { eq: "investment" } }) {
      nodes {
        custom {
          investment_sub_title
        }
      }
    }
    allWordpressWpInvestment(
      filter: { custom: { current_investment_post: { eq: "no" } } }
    ) {
      nodes {
        categories {
          name
        }
        content
        custom {
          current_investment_post
          title
          slug
          investment_image_post {
            investment_post_images {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt_text
            }
          }
        }
      }
    }
  }
`
